import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import NavBarTop from "../Reusable_Components/NavBarTop";
import LeftNavBar from "../Reusable_Components/LeftNavBar";
import TableComponent from "../Reusable_Components/TableComponent";
import Pagination from "../Reusable_Components/Pagination";
import { GoSearch } from 'react-icons/go';
import { RECORDS_PER_PAGE_METER_MASTER } from "../../Config";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UploadComponent from '../Reusable_Components/UploadComponent';
import axios from 'axios';
import Loader from '../Reusable_Components/loader';
import {
    getInvoicePayments,
    commercialHeadGetAllFacilitiesForNav,
    SET_ERROR_FALSE, CONTRACT_API_LOADER_TRIGGER_TRUE, uploadPaymentDocuments, getAllDocumentsForPayment, handleDocumentLoaderTrue
} from '../../Redux/Actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { base_url } from '../../utilities';
import SearchableDropdown from '../Reusable_Components/SearchableDropdown';
import AlertPopUp from '../Reusable_Components/Alert';
import Footer from '../Login/Footer';
import { formatNumericparseFloatInput, formatNumericInput } from '../Reusable_Components/ReusableFunctions'
import { set } from 'date-fns';

const FinanceManagerPayments = () => {
    const dispatch = useDispatch();
    const contractApiLoader = useSelector(state => state.contractApiLoader);
    const allPaymentDocuments = useSelector(state => state.allPaymentDocuments);
    const documentLoaderTrigger = useSelector(state => state.documentLoaderTrigger);

    useEffect(() => {
        if (allPaymentDocuments) {
            setDocumentListArray(allPaymentDocuments)
            // setIsLoading(false)
        }
    }, [allPaymentDocuments])


    useEffect(() => {
        dispatch(commercialHeadGetAllFacilitiesForNav())
    }, [dispatch]);

    const error = useSelector(state => state.error);
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])
    const [alertAction, setAlertAction] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setErrorValue(error?.response?.data.message);
        if (error?.response?.data.message || error?.data?.data[1]?.message) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.data?.data[1] ? error?.data?.data[1].message : error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                    dispatch({
                        type: SET_ERROR_FALSE,
                        payload: null
                    })
                }
            }])
        }
        setIsLoading(false);
    }, [error])

    const [facilityList, setFacilityList] = useState([]);
    const [errorValue, setErrorValue] = useState('');
    const [selectedfacility, setSelectedFacility] = useState(`${localStorage.getItem("selected_city_name")}, ${localStorage.getItem("selected_facility_name")}`);
    const facilitiesListForNav = useSelector(state => state.facilitiesListForNav);
    const [selectedFacilityId, setSelectedFacilityId] = useState(localStorage.getItem("selected_facility_id"));
    const [selectedFacilityName, setSelectedFacilityName] = useState(localStorage.getItem("selected_facility_name"));
    const [selectedCityName, setSelectedCityName] = useState(localStorage.getItem("selected_city_name"));
    const [isFacilitySelected, setIsFacilitySelected] = useState(selectedfacility);


    useEffect(() => {
        if (facilitiesListForNav) {
            const options = facilitiesListForNav?.map((facility) => `${facility.city}, ${facility.name}`);
            setFacilityList(options);
        }
    }, [facilitiesListForNav]);

    const [searchText, setSearchText] = useState('');
    const [amountDue, setAmountDue] = useState();




    const [invoiceTypeFilter, setInvoiceTypeFilter] = useState('');
    const [paymentStatusFilter, setPaymentStatusFilter] = useState('due');
    const [billingMonthFilter, setBillingMonthFilter] = useState(dayjs().month() + 1);
    const [billingYearFilter, setBillingYearFilter] = useState(dayjs().year());


    useEffect(() => {
        let filter_condition_validate = {};
        if (invoiceTypeFilter === '') {
            filter_condition_validate = {
                facility_id: localStorage.getItem('selected_facility_id'),
                invoice_month: billingMonthFilter,
                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        } else {
            filter_condition_validate = {
                facility_id: localStorage.getItem('selected_facility_id'),
                invoice_type: invoiceTypeFilter,
                invoice_month: billingMonthFilter,
                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        }
        dispatch(getInvoicePayments(
            {
                page_number: 1,
                items_per_page: RECORDS_PER_PAGE_METER_MASTER,
                search_text: searchText,
                filter_condition: filter_condition_validate
            }
        ))
    }, [dispatch])

    const totalRecords = useSelector((state) => state.allInvoicePayments?.info?.total_no_of_records);
    const currentPaginationPage = useSelector((state) => state.allInvoicePayments?.info?.page_number);
    const allInvoicePayments = useSelector((state) => state.allInvoicePayments?.data);


    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const [contractName, setContractName] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [invoiceType, setInvoiceType] = useState('');
    const [issueDate, setIssueDate] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [invoicedAmount, setInvoicedAmount] = useState('');
    const [totalReceivedAmount, setTotalReceivedAmount] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [selectedPaymentId, setSelectedPaymentId] = useState('');

    const [totalBaseAmount, setTotalBaseAmount] = useState('');
    const [totalTDSAmount, setTotalTDSAmount] = useState('');
    const [totalCGSTAmount, setTotalCGSTAmount] = useState('');
    const [totalSGSTAmount, setTotalSGSTAmount] = useState('');
    const [totalIGSTAmount, setTotalIGSTAmount] = useState('');
    const [tdsPercentage, setTdsPercentage] = useState('');


    const handleViewActionClicked = (data) => {

        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true
        })
        dispatch(getAllDocumentsForPayment(data.payment_id))
        axios.get(base_url + "payments", {
            params: {
                filter_condition: JSON.stringify({
                    payment_id: data.payment_id
                })
            }
        })
            .then((response) => {
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false
                })
                const data1 = response.data.data[0];
                setContractName(data1.contract_name);
                setInvoiceNumber(data1.invoice_number);
                setInvoiceType(data1.invoice_type);
                setIssueDate(data1.issue_date);
                setDueDate(data1.due_date);
                setInvoicedAmount(data1.invoiced_amount);
                setTotalReceivedAmount(data1.total_received_amount);
                setPaymentStatus(data1.status);
                setAmountDue(data1.amount_due);
                setPaymentRecordsRows(data1.payment_details);
                setSelectedPaymentId(data1.payment_id);
                setTotalBaseAmount(data1.total_base_amount);
                setTotalTDSAmount(data1.tds_amount);
                setTotalCGSTAmount(data1.total_cgst);
                setTotalSGSTAmount(data1.total_sgst);
                setTotalIGSTAmount(data1.total_igst);
                setTdsPercentage(data1.tds_percentage);

            })
            .catch((error) => {
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false
                })
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error ')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        setAlertShow(false)
                        setAlertValue('')
                        setAlertMessage('')
                        setAlertType('')
                        backFunction()
                    }
                }])
            })


        setCurrentPage(2)
    }

    const handleEditActionClicked = (data) => {
        console.log('Edit action clicked for:', data);
    }

    const handledPaginationNextClicked = () => {

        let filter_condition_validate = {};
        if (invoiceTypeFilter === '') {
            filter_condition_validate = {
                facility_id: selectedFacilityId,
                invoice_month: billingMonthFilter,
                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        } else {
            filter_condition_validate = {
                facility_id: selectedFacilityId,
                invoice_type: invoiceTypeFilter,
                invoice_month: billingMonthFilter,

                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        }
        dispatch(getInvoicePayments({
            page_number: currentPaginationPage + 1,
            items_per_page: RECORDS_PER_PAGE_METER_MASTER,
            search_text: searchText,
            filter_condition: filter_condition_validate
        }))

    }

    const handledPaginationPrevClicked = () => {
        let filter_condition_validate = {};
        if (invoiceTypeFilter === '') {
            filter_condition_validate = {
                facility_id: selectedFacilityId,
                invoice_month: billingMonthFilter,
                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        } else {
            filter_condition_validate = {
                facility_id: selectedFacilityId,
                invoice_type: invoiceTypeFilter,
                invoice_month: billingMonthFilter,

                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        }
        dispatch(getInvoicePayments({
            page_number: currentPaginationPage - 1,
            items_per_page: RECORDS_PER_PAGE_METER_MASTER,
            search_text: searchText,
            filter_condition: filter_condition_validate
        }))

    }

    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState('1');

    const [paymentRecordsRows, setPaymentRecordsRows] = useState(
        [{
            payment_date: "",
            amount_paid: "",
            account_no: "",
            instrumentation_type: "",
            note: "",
        }]
    );

    const handleAddPaymentRecordRow = () => {
        const newPaymentRecordsRow = {
            payment_id: selectedPaymentId,
            payment_date: "",
            amount_paid: "",
            account_no: "",
            instrumentation_type: "",
            note: "",
        }
        setPaymentRecordsRows([...paymentRecordsRows, newPaymentRecordsRow]);
    }

    const handleRemovePaymentRecordRow = (index) => {
        const newPaymentRecordsRows = paymentRecordsRows.filter((row, i) => i !== index);
        setPaymentRecordsRows(newPaymentRecordsRows);
    }

    const handleBillingPeriodChange = (newValue) => {
        setBillingMonthFilter(dayjs(newValue).month() + 1);
        setBillingYearFilter(dayjs(newValue).year());
    }

    const applyFilterClicked = () => {
        localStorage.setItem("selected_facility_id", selectedFacilityId);
        localStorage.setItem("selected_facility_name", selectedFacilityName);
        localStorage.setItem("selected_city_name", selectedCityName);

        let filter_condition_validate = {};

        if (invoiceTypeFilter === '') {
            filter_condition_validate = {
                facility_id: selectedFacilityId,
                invoice_month: billingMonthFilter,
                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        } else {
            filter_condition_validate = {
                facility_id: selectedFacilityId,
                invoice_type: invoiceTypeFilter,
                invoice_month: billingMonthFilter,
                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        }
        dispatch(getInvoicePayments(
            {
                page_number: 1,
                items_per_page: RECORDS_PER_PAGE_METER_MASTER,
                search_text: searchText,
                filter_condition: filter_condition_validate
            }
        ))
    }

    const clearFilterClicked = () => {
        setInvoiceTypeFilter('');
        setPaymentStatusFilter('due');
        setBillingMonthFilter(1);
        setBillingYearFilter(2025);
        setSearchText('');
        dispatch(getInvoicePayments(
            {
                page_number: 1,
                items_per_page: RECORDS_PER_PAGE_METER_MASTER,
                search_text: '',
                filter_condition: {
                    facility_id: localStorage.getItem('selected_facility_id'),
                    invoice_month: dayjs().month() + 1,
                    invoice_year: dayjs().year(),
                    status: 'due'
                }
            }
        ))
    }

    const handlePaymentRecordsRowChange = (e, index, key) => {
        const newPaymentRecordsRows = paymentRecordsRows.map((row, i) => {
            if (i === index) {
                return {
                    ...row,
                    [key]: key === 'amount_paid' ? parseFloat(formatNumericparseFloatInput(e.target.value)) : e.target.value
                }
            }
            return row;
        });
        setPaymentRecordsRows(newPaymentRecordsRows);
    }

    const backFunction = () => {
        setCurrentPage(1)
        setContractName('');
        setInvoiceNumber('');
        setInvoiceType('');
        setIssueDate('');
        setDueDate('');
        setInvoicedAmount('');
        setTotalReceivedAmount('');
        setPaymentStatus('');
        setAmountDue('');
        setTotalTDSAmount('');
        setTotalBaseAmount('');
        setTotalCGSTAmount('');
        setTotalSGSTAmount('');
        setTotalIGSTAmount('');
        setDocumentListArray([]);
        setTdsPercentage('');
        setEditTdsClicked(false);
        setPaymentRecordsRows([{
            payment_date: "",
            amount_paid: "",
            account_no: "",
            instrumentation_type: "",
            note: "",
        }]);
    }

    const handleSavePayment = () => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true
        })
        setIsLoading(true);
        let body = {}
        if(editTdsClicked){
            body = {
                payment_id: selectedPaymentId,
                tds_percentage: tdsPercentage,
                payments: paymentRecordsRows
            }
        }
        else{
            body = {
                payment_id: selectedPaymentId,
                payments: paymentRecordsRows
            }
        }
        
        axios.put(base_url + "payments", body)
            .then((response) => {
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false
                })

                setIsLoading(false);
                setAlertShow(true)
                setAlertType('success')
                setAlertMessage('Success')
                setAlertValue(<>
                    <p>
                        Payment records saved successfully
                    </p>
                </>)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        let filter_condition_validate = {};

                        if (invoiceTypeFilter === '') {
                            filter_condition_validate = {
                                facility_id: selectedFacilityId,
                                invoice_month: billingMonthFilter,
                                invoice_year: billingYearFilter,
                                status: paymentStatusFilter
                            }
                        } else {
                            filter_condition_validate = {
                                facility_id: selectedFacilityId,
                                invoice_type: invoiceTypeFilter,
                                invoice_month: billingMonthFilter,
                                invoice_year: billingYearFilter,
                                status: paymentStatusFilter
                            }
                        }
                        dispatch(getInvoicePayments(
                            {
                                page_number: 1,
                                items_per_page: RECORDS_PER_PAGE_METER_MASTER,
                                search_text: searchText,
                                filter_condition: filter_condition_validate
                            }
                        ))
                        setCurrentPage(1)
                        backFunction()
                        setAlertShow(false)
                    }
                }])
            })
            .catch((error) => {
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false
                })
                setIsLoading(false);
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error ')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        setAlertShow(false)
                        // backFunction()
                    }
                }])
            })
    }

    const [uploadedFiles, setUploadedFiles] = useState();

    const handleFilesSelected = (files) => {
        const processedFiles = [];
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = function () {
                const base64FileContent = reader.result.split(",")[1];
                const required_id = selectedPaymentId
                const processedFile = {
                    file_content: base64FileContent,
                    object_type: "payments_document",
                    file_description: file.description,
                    file_type: file.file.type.split("/")[1],
                    file_name: file.file.name,
                    file_size: file.file.size,
                    flag: file.flag,
                    user_id: localStorage.getItem('username'),
                    payment_id: required_id,
                };
                processedFiles.push(processedFile);
                if (processedFiles.length === files.length) {
                    setUploadedFiles(processedFiles);
                }
            };
            reader.readAsDataURL(file.file);
        });
    };

    const [documentsListArray, setDocumentListArray] = useState([])
    const handleDocumentUpload = (index) => {
        dispatch(handleDocumentLoaderTrue())
        dispatch(uploadPaymentDocuments(
            {
                files: [uploadedFiles[index]]
            }
        ))
    }

    const handleErrorFromDocument = (value) => {
        setErrorValue(value)
    }

    const handleEnable = (each) => {
        return false;
    }

    const [editTdsClicked, setEditTdsClicked] = useState(false);

    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
                {
                    contractApiLoader ? <Loader /> : null
                }
                <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
            </>
            {
                currentPage === 1 &&
                <div className='meter_reports_container'>


                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                        <div className='d-flex align-items-center gap-3 mb-3'>
                            <h3 className="invoice-heading" >Payments</h3>
                        </div>
                    </div>



                    <div className='fm-invoice-con-filters' style={{ zIndex: 100 }}>
                        <div style={{ width: '280px' }} className="fin_man_billing_period">
                            <p style={{ marginBottom: '-15px' }}>Facility:&nbsp;</p>
                            <SearchableDropdown
                                style={{ zIndex: 100 }}
                                // className = "searchable-dropdown2"
                                options={facilityList ? facilityList : []} //facilityList is the array of facilities
                                selectedOption={selectedfacility}
                                noOptionsAvailable="Facility unavailable"
                                onChange={(e) => {
                                    setSelectedFacility(e.value)
                                    const selectedFacility = facilitiesListForNav?.find((facility) => `${facility.city}, ${facility.name}` === e.value);
                                    setSelectedFacilityName(selectedFacility?.name);
                                    setSelectedCityName(selectedFacility?.city);
                                    setSelectedFacilityId(selectedFacility?.facility_id);
                                    setIsFacilitySelected(true);
                                }}

                                placeholder="Select Facility"
                                isDisabled={false}
                            />
                        </div>
                        <div style={{ width: '140px' }} className="fin_man_billing_period">
                            <p style={{ marginBottom: '5px' }}>Invoice Type:&nbsp;</p>
                            <select
                                value={invoiceTypeFilter}
                                onChange={(e) => {
                                    setInvoiceTypeFilter(e.target.value);
                                }}
                                className="invoice-select-date"
                                style={{ width: "100%", height: "2.5rem" }}
                            >
                                <option value={""}>All Invoices</option>
                                <option value="water">Water</option>
                                <option value="electricity">Electricity</option>
                                <option value="slf">SLF</option>
                                <option value="fms">FMS</option>
                                <option value="miscellaneous">Miscellaneous</option>
                                { }
                            </select>
                        </div>
                        <div style={{ width: '120px' }} className="fin_man_billing_period">
                            <p style={{ marginBottom: '5px' }}>Payment Status:&nbsp;</p>
                            <select
                                value={paymentStatusFilter}
                                onChange={(e) => {
                                    setPaymentStatusFilter(e.target.value);
                                }}
                                className="invoice-select-date"
                                style={{ width: "100%", height: "2.5rem" }}
                            >
                                <option value="due">Due</option>
                                <option value="partiallypaid">Partially-Paid</option>
                                <option value="fullypaid">Fully-Paid</option>

                                { }
                            </select>
                        </div>
                        <div className="fin_man_billing_period" style={{ width: '180px' }}>
                            <p style={{ marginBottom: '1px' }}>Billing Period:&nbsp;</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label=""
                                    value={dayjs(`${billingYearFilter}-${billingMonthFilter}`)}
                                    views={['month', 'year']}
                                    // minDate={dayjs('2022-01-01')} // Set minimum selectable date
                                    // maxDate={dayjs('2025-01-08')} // Set minimum selectable date
                                    onChange={(newValue) => {
                                        handleBillingPeriodChange(newValue);
                                    }
                                    }
                                    sx={{
                                        '.MuiInputBase-root': {
                                            height: '40px', // Adjust height
                                            backgroundColor: 'white', // Set background to white
                                            borderRadius: '6px', // Optional: Rounded corners
                                            fontSize: '14px', // Optional: Adjust text size
                                        },
                                    }}
                                    slotProps={{
                                        textField: {
                                            InputProps: {
                                                readOnly: true, // Prevent typing in the input field
                                            },
                                            onKeyDown: (e) => e.preventDefault(), // Block keyboard inputs
                                        },
                                    }}
                                />
                            </LocalizationProvider>


                        </div>
                        <div className='d-flex align-items-center gap-3 mb-3'>
                            <div className='fin_man_meter_search_bar_container'>
                                <input style={{ marginTop: '20px', height: '2.5rem' }} type='search' placeholder='Search' value={searchText}
                                    onChange={handleSearchChange}
                                    className='fin_man_meter_search_bar' />
                                <GoSearch

                                    style={{ marginTop: '17px' }} className='fin_man_meter_search_bar_icon C-pointer' />
                            </div>

                        </div>
                        <div className='d-flex align-items-center gap-3'>
                            <button className="invoice_btn" style={{ marginTop: '4px', color: 'white', height: '20px !important' }} onClick={() => {
                                applyFilterClicked()
                            }}>Apply</button>
                            <button className="invoice_btn" style={{ marginTop: '4px', color: 'white', height: '20px !important' }}
                                onClick={() => {
                                    clearFilterClicked()
                                }}
                            >Clear</button>

                        </div>

                    </div>


                    <div className='table_container_commercial_head'>
                        <TableComponent
                            headers={[
                                {
                                    name: "Contract Name ",
                                    type: "text",
                                    key: "contract_name",
                                    sort: true
                                },
                                {
                                    name: "Invoice Number",
                                    type: "text",
                                    key: "invoice_number",
                                    sort: true
                                }, {
                                    name: "Invoice Type ",
                                    type: "text",
                                    key: "invoice_type",
                                    sort: true
                                },
                                {
                                    name: "Issue Date",
                                    type: "date",
                                    key: "issue_date",
                                    sort: true
                                },
                                {
                                    name: "Due Date",
                                    type: "date",
                                    key: "due_date",
                                    sort: true
                                },
                                {
                                    name: "Invoiced Amount",
                                    type: "number",
                                    key: "invoiced_amount",
                                    sort: true,
                                    formatNumberWithComas: true
                                },
                                {
                                    name: "Total Received Amount",
                                    type: "number",
                                    key: "total_received_amount",
                                    sort: true,
                                    formatNumberWithComas: true
                                }, {
                                    name: "Amount Due",
                                    type: "number",
                                    key: "amount_due",
                                    sort: true,
                                    formatNumberWithComas: true
                                },
                                {
                                    name: "Payment Status",
                                    type: "text",
                                    key: "status",
                                    sort: true
                                },
                                {
                                    name: "Updated By",
                                    type: "text",
                                    key: "updated_by",
                                    sort: true
                                },
                                {
                                    name: "Last Updated At",
                                    type: "time_stamp",
                                    key: "updated_at",
                                    sort: true
                                },
                                {
                                    name: "Action",
                                    type: "button",
                                    buttons: [{
                                        name: "View",
                                        icon: "./images/icons/Vectoredit.svg",
                                        disable: handleEnable,
                                        action: (data) => handleViewActionClicked(data)
                                    },
                                    {
                                        name: "Edit",
                                        icon: "./images/icons/sendnotification.svg",
                                        disable: handleEnable,
                                        action: (data) => handleEditActionClicked(data)
                                    }

                                    ]
                                }]}

                            data={allInvoicePayments}
                        />

                    </div>
                    <Pagination
                        handledPaginationNextClicked={handledPaginationNextClicked}
                        handledPaginationPrevClicked={handledPaginationPrevClicked}
                        isApplyClicked
                        totalRecords={totalRecords}
                        recordsPerPage={RECORDS_PER_PAGE_METER_MASTER}
                        currentPaginationPage={currentPaginationPage}
                    />
                </div>
            }
            {
                currentPage === 2 &&
                <div className="newPaymentContainer">
                    <div style={{ display: "flex", flexDirection: "column", margin: '10px', marginBottom: "100px" }}>
                        <div>
                            <h2>Payment Details</h2>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <label className='add-facility-modal-label' style={{ flexWrap: "nowrap", marginRight: "10px" }}>Contract Name: </label>
                            <spam><strong>{"Contract Name"}</strong></spam>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(k) => setActiveTab(k)}
                                id="uncontrolled-tab-example"
                                className="mb-0"
                            >
                                <Tab eventKey="1" title="Payment Details"
                                    className="assetTabs"
                                // disabled={!viewClicked && !editClicked}
                                >
                                    <div className="infoBody">
                                        <div>
                                            <h7 style={{ fontWeight: "bold" }}>Invoice Information</h7>
                                            <div className="infoBodyInputs">
                                                <div className="responsiveInputSection">
                                                    <div >
                                                        <label className='add-facility-modal-label'
                                                        >Contract Name </label>
                                                        <input type='text'
                                                            value={contractName}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />

                                                    </div>
                                                    <div >
                                                        <label className='add-facility-modal-label'
                                                        >Invoice Number </label>
                                                        <input type='text'
                                                            value={invoiceNumber}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />

                                                    </div>
                                                    <div>
                                                        <label className='add-facility-modal-label'>Invoice Type</label>
                                                        <input type='text'
                                                            value={invoiceType}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                    </div>
                                                </div>
                                                <div className="responsiveInputSection">
                                                    <div >
                                                        <label className='add-facility-modal-label'
                                                        >Issued Date </label>
                                                        <input type='date'
                                                            value={issueDate}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                    </div>
                                                    <div >
                                                        <label className='add-facility-modal-label'
                                                        >Due Date </label>
                                                        <input type='date'
                                                            value={dueDate}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                    </div>
                                                    <div>
                                                        <label className='add-facility-modal-label'>Payment Status</label>
                                                        <input type='text'
                                                            value={paymentStatus}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                    </div>
                                                </div>
                                                <div className="responsiveInputSection">
                                                    <div >
                                                        <label className='add-facility-modal-label'
                                                        >Invoiced Amount</label>
                                                        <input type='number'
                                                            value={invoicedAmount}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />

                                                    </div>
                                                    <div >
                                                        <label className='add-facility-modal-label'
                                                        >Total Amount Received </label>
                                                        <input
                                                            type='number'
                                                            value={totalReceivedAmount}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                    </div>
                                                    <div>
                                                        <label className='add-facility-modal-label'>Due Amount</label>
                                                        <input type='text'
                                                            value={amountDue}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                    </div>
                                                </div>
                                                <div className="responsiveInputSection">
                                                    <div >
                                                        <label className='add-facility-modal-label'
                                                        >Base Amount</label>
                                                        <input type='number'
                                                            value={totalBaseAmount}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />

                                                    </div>
                                                    <div >
                                                        
                                                            <label className='add-facility-modal-label'
                                                            >TDS % </label>
                                                            <div style={{display: "flex", flexDirection: "row",justifyContent: "space-between"}}>
                                                            <input
                                                                type='number'
                                                            value={tdsPercentage}
                                                            disabled={!editTdsClicked}
                                                            style={{ width: "80%" }}
                                                            onChange={(e) => {
                                                                if(e.target.value <= 100){
                                                                setTdsPercentage(formatNumericparseFloatInput(e.target.value))
                                                                }
                                                            }}
                                                            className='form-control' />
                                                            {
                                                                editTdsClicked ?
                                                                null 
                                                                :
                                                                <div
                                                                    style={{
                                                                        background: "grey",
                                                                        padding: "9px 10px",
                                                                        borderRadius: "5px",
                                                                        width: "15%",
                                                                    }}
                                                                    className="C-pointer"
                                                                    onClick={() => {
                                                                        setEditTdsClicked(true)
                                                                    }}
                                                                >
                                                            <img
                                                                src="./images/icons/Edit.svg"
                                                                alt="edit"
                                                                style={{ height: "20px" }}
                                                            />
                                                        </div>

                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label className='add-facility-modal-label'>TDS Amount</label>
                                                        <input type='text'
                                                            value={totalTDSAmount}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                    </div>
                                                </div>
                                                <div className="responsiveInputSection">
                                                    <div >
                                                        <label className='add-facility-modal-label'
                                                        >CGST</label>
                                                        <input type='number'
                                                            value={totalCGSTAmount}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />

                                                    </div>
                                                    <div >
                                                        <label className='add-facility-modal-label'
                                                        >SGST</label>
                                                        <input
                                                            type='number'
                                                            value={totalSGSTAmount}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                    </div>
                                                    <div>
                                                        <label className='add-facility-modal-label'>IGST</label>
                                                        <input type='text'
                                                            value={totalIGSTAmount}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div>
                                            <h7 style={{ fontWeight: "bold" }}>Payment Records</h7>
                                            <div>

                                                {
                                                    paymentRecordsRows.reduce((sum, item) => sum + (parseFloat(item.amount_paid) || 0), 0) <= (parseFloat(invoicedAmount) - parseFloat(totalTDSAmount)) ?
                                                        null
                                                        :
                                                        <spam style={{ color: "red" }}> Total amount paid should be less than or equal to {invoicedAmount - totalTDSAmount}</spam>
                                                }

                                            </div>
                                            <div className="infoBodyInputs">
                                                <div className='license_fee_details_table'>
                                                    <table>
                                                        <thead className='fin_man_table_head commercial-head-table text-center'>
                                                            <tr>
                                                                <th>S.No</th>
                                                                <th>Payment Date <span style={{ color: "red" }}>*</span></th>
                                                                <th>Payment Amount (<span><img src="./images/icons/rupee.svg" alt='ruppee symbol' /></span>)<span style={{ color: "red" }}>*</span></th>
                                                                <th>Account No. <span style={{ color: "red" }}>*</span></th>
                                                                <th style={{ textWrap: "nowrap" }}>Mode Of Payment<span style={{ color: "red" }}>*</span></th>
                                                                <th style={{ textWrap: "nowrap" }}>note</th>

                                                            </tr>

                                                        </thead>
                                                        <tbody className='fin_man_table_body'>
                                                            {
                                                                paymentRecordsRows.length > 0 ?
                                                                    paymentRecordsRows.map((row, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>
                                                                                    <input type='date'
                                                                                        value={row?.payment_date}
                                                                                        disabled={row?.payment_detail_id}
                                                                                        onChange={(e) => handlePaymentRecordsRowChange(e, index, 'payment_date')}
                                                                                        className='form-control'
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input type='number'
                                                                                        value={row?.amount_paid}
                                                                                        disabled={row?.payment_detail_id}
                                                                                        onChange={(e) => handlePaymentRecordsRowChange(e, index, 'amount_paid')}
                                                                                        className='form-control'
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input type='text'
                                                                                        value={row?.account_no}
                                                                                        disabled={row?.payment_detail_id}
                                                                                        onChange={(e) => handlePaymentRecordsRowChange(e, index, 'account_no')}
                                                                                        className='form-control'
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input type='text'
                                                                                        value={row.instrumentation_type}
                                                                                        disabled={row?.payment_detail_id}
                                                                                        onChange={(e) => handlePaymentRecordsRowChange(e, index, 'instrumentation_type')}
                                                                                        className='form-control'
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input type='text'
                                                                                        value={row.note}
                                                                                        disabled={row?.payment_detail_id}
                                                                                        onChange={(e) => handlePaymentRecordsRowChange(e, index, 'note')}
                                                                                        className='form-control'
                                                                                    />
                                                                                </td>

                                                                            </tr>
                                                                        )
                                                                    })
                                                                    //   </>
                                                                    :
                                                                    <tr>
                                                                        <td colSpan={5} className='text-center'>No Records Found, Please add a record</td>
                                                                    </tr>
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                                {
                                                    paymentRecordsRows.length === 0 &&
                                                    <div style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
                                                        <img style={{ height: "18px", marginRight: "10px" }}
                                                            onClick={handleAddPaymentRecordRow}
                                                            src="./images/icons/PlusaddItem.svg" alt="add"
                                                            title="Add row below"
                                                        />
                                                    </div>
                                                }
                                                {
                                                    paymentRecordsRows[paymentRecordsRows.length - 1]?.payment_date && paymentRecordsRows[paymentRecordsRows.length - 1]?.amount_paid && paymentRecordsRows[paymentRecordsRows.length - 1]?.account_no && paymentRecordsRows[paymentRecordsRows.length - 1]?.instrumentation_type &&
                                                    <div style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
                                                        {paymentRecordsRows.reduce((sum, item) => sum + (parseFloat(item.amount_paid) || 0), 0) < (parseFloat(invoicedAmount) - parseFloat(totalTDSAmount)) ?
                                                            <img style={{ height: "18px", marginRight: "10px" }}
                                                                onClick={handleAddPaymentRecordRow}
                                                                src="./images/icons/PlusaddItem.svg" alt="add"
                                                                title="Add row below"
                                                            />
                                                            :
                                                            null

                                                        }
                                                        {
                                                            ((paymentRecordsRows.length > 1) && !(paymentRecordsRows[paymentRecordsRows.length - 1]?.payment_detail_id)) &&
                                                            <img style={{ height: "18px" }} src="./images/icons/MinusremoveItem.svg"
                                                                onClick={() => handleRemovePaymentRecordRow(paymentRecordsRows.length - 1)}
                                                                alt="remove"
                                                                title="Delete last row"
                                                            />
                                                        }
                                                    </div>
                                                }


                                            </div>
                                        </div>
                                        <div style={{ marginTop: "20px" }}>
                                            <UploadComponent
                                                onFilesSelected={handleFilesSelected}
                                                uploadMethod="single"
                                                viewMode={false}
                                                uploadedFileData={documentsListArray}
                                                uploadActionClicked={handleDocumentUpload}
                                                // deleteSpecificFileMain={confirmDeleteDocument}
                                                maxNumberOfFiles={5}
                                                handleErrorFromDocument={handleErrorFromDocument}
                                                idType={"payments"}
                                                acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                                            />
                                        </div>
                                    </div>

                                </Tab>
                            </Tabs>
                        </div>
                        <div style={{ marginTop: "20px", }}>
                            <div style={{ display: "flex", justifyContent: "end", }}>
                                {
                                    tdsPercentage === '' ?
                                    null:
                                    <button className="invoice_btn" style={{ marginTop: "20px", color: "white" }}
                                        disabled={
                                            (
                                            (paymentRecordsRows.length === 0) ||
                                            (paymentRecordsRows[paymentRecordsRows.length - 1]?.payment_date && paymentRecordsRows[paymentRecordsRows.length - 1].amount_paid && paymentRecordsRows[paymentRecordsRows.length - 1].account_no && paymentRecordsRows[paymentRecordsRows.length - 1].instrumentation_type)
                                            )? 
                                             false 
                                             : 
                                             true
                                            }
                                        onClick={handleSavePayment}
                                    >Save</button>

                                }
                                <button className="invoice_btn"
                                    onClick={() => {
                                        if (paymentStatus === "fullypaid") {
                                            backFunction()
                                        }
                                        else {
                                            setAlertShow(true)
                                            setAlertType('question')
                                            setAlertMessage('Confirmation')
                                            setAlertValue("Are you sure you want to cancel? Any changes made will not be saved.")
                                            setAlertButtons([{
                                                name: 'Yes', action: () => {
                                                    setAlertShow(false)
                                                    backFunction()
                                                }
                                            }, {
                                                name: 'No', action: () => {
                                                    setAlertShow(false)
                                                }
                                            }])
                                        }

                                    }}
                                    style={{ marginTop: "20px", color: "white", marginLeft: "10px" }}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </>

    )
}
export default FinanceManagerPayments;
